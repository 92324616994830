export const articles = [
    {
        image: require('./images/articles/microprofile.jpg'),
        title: 'KumuluzEE is now part of MicroProfile',
        date: '2017-04-27',
        content: "KumuluzEE has joined MicroProfile. The MicroProfile is a baseline platform definition that optimizes Enterprise Java for a microservices architecture and delivers application portability across multiple MicroProfile runtimes. KumuluzEE is now a member of the MicroProfile community, alongside with IBM, London Java Community, redhat, Tomitribe, payara, SOU Java, hazelcast and FUJITSU. Read more on <a target='_blank' href='http://MicroProfile.io'>MicroProfile.io</a>."
    },
    {
        image: require('./images/articles/javaonesf.jpg'),
        title: 'JavaOne Presentation',
        date: '2016-09-16',
        content: "Tilen Faganel and Matjaz B. Juric held a session »Migrate Java EE Applications to Microservices and Oracle Java Cloud« on JavaOne 2016 in San Francisco. They demonstrate how to migrate existing Java EE applications into microservices. The session explains how to define microservice boundaries, how to do circuit breakers, and how to pack and bootstrap Java EE microservices. Link to presentation <a target='_blank' href='https://www.youtube.com/watch?v=s95NonOYflk&feature=youtu.be&list=PLPIzp-E1msrYicmovyeuOABO4HxVPlhEA'>video</a>."
    },
    {
        image: require('./images/articles/duke_java.jpg'),
        title: "2015 Java Duke's Choice Award Winner",
        date: '2015-10-28',
        content: "KumuluzEE has won the prestigious <a target='_blank' href='https://www.oracle.com/corporate/pressrelease/dukes-award-102815.html'>2015 Java Duke's Choice Award</a>. The awards celebrate innovations that are based on the Java technology. Oracle announced the winners at the JavaOne conference in San Francisco. <br/><br/> The Duke's Choice Award is the Java community equivalent of winning an Oscar. The award celebrates extreme innovation using Java technology. The primary judging criteria for this prestigious award is innovation, putting small developer shops on equal footing with global giants. Java Duke's Choice Award winners are recognition as an elite member of the Java ecosystem. Previous award winners include Hadoop, Jenkins, TomEE, and several other well-known products."
    },
    {
        image: require('./images/articles/eurocloud.jpg'),
        title: '2014 EuroCloud Award',
        date: '2014-06-20',
        content: "Sunesis won <a target='_blank' href='http://eurocloud.si/lang/sl/2014/06/20/zmagovalci-eurocloud-award-2014-tekmovanja-v-sloveniji-so-znani/'>EuroCloud Award</a> for the most innovative cloud service for its family of Kumuluz products. The expert jury has recognized the Kumuluz integration platform as very innovative. They also recognized the benefits of the Kumuluz integration platform compared to the competition."
    },
    {
        image: require('./images/articles/sunesis_startup.jpg'),
        title: 'Slovenian Start:up of the Year 2014 Finalist',
        date: '2014-05-15',
        content: "Start-up Sunesis has been selected for the <a target='_blank' href='http://startup.si/en-us/startup/112/sunesis'>Start-up of the Year Finalist</a>!"
    },
    {
        image: require('./images/articles/java_magazine.jpg'),
        title: 'Java Magazine Article',
        date: '2016-02-28',
        content: "we have published an article in <a target='_blank' href='http://www.javamagazine.mozaicreader.com/JanFeb2016#&pageSet=80&page=0&contentItem=0'>Java Magazine</a> about building microservices with our KumuluzEE framework. "
    },
    {
        image: require('./images/articles/newspaper.jpg'),
        title: 'KumuluzEE in the news ',
        date: '2016-02-15',
        content: "Many foreign news media and blogs have reported about KumuluzEE, including: <ul><li><a target='_blank' href='http://www.forbes.com/sites/oracle/2015/11/14/cloud-and-internet-of-things-fuel-javas-ongoing-momentum/#4ba020f32a7d'>Forbes</a></li><li><a target='_blank' href='https://dzone.com/articles/2015-dukes-choice-award-winners-announced'>DZone</a></li><li><a target='_blank' href='http://www.infoq.com/news/2016/02/javaee-microservices'>InfoQ</a></li> <li><a target='_blank' href='https://community.oracle.com/docs/DOC-949972'>Oracle</a></li><li><a target='_blank' href='http://www.guigarage.com/2015/11/dolphin-platform-kumuluzee-javaee-microservices-with-dynamic-and-rich-frontends/'>GuiGarage</a></li><li>Blogs <a target='_blank' href='http://www.itdadao.com/article/374483/'>IT Avenue</a> and <a target='_blank' href='http://blog.arungupta.me/docker-kubernetes-microservices-devoxx-2015/'>Arun Gupta</a></li></ul>"
    },
    {
        image: require('./images/articles/finance.jpg'),
        title: 'Sunesis on the top 10 start-ups list',
        date: '2015-12-31',
        content: "The leading Slovenian financial newspaper Finance put Sunesis on the list of <a target='_blank' href='https://www.finance.si/8839935/Top-10-Start-upi'>10 best Start-up companies</a> in Slovenia in year 2015."
    },
    {
        image: require('./images/articles/ten_promising.jpg'),
        title: 'We are one of 10 most promising technology companies',
        date: '2015-12-24',
        content: "The Slovenian financial news blog Startaj.si has presented <a target='_blank' href='https://startaj.finance.si/8839776/10-vro%C4%8Dih-start-upov-za-leto-2016'>the most promising technology companies</a>. We are one of them!"
    },
    {
        image: require('./images/articles/finance2.jpg'),
        title: 'Best Entrepreneurial Idea',
        date: '2014-10-19',
        content: "The leading Slovenian financial newspaper Finance named Kumuluz as a candidtate for <a target='_blank' href='https://www.finance.si/8811480/Najpodjetni%C5%A1ka-ideja-Kumuluz-hitro-in-preprosto-pove%C5%BEe-aplikacije-v-oblaku-z-zalednimi'>Best Entrepreneurial Idea 2014/2015</a>. The Finance newspaper has held Best Entrepreneurial Idea competition for 12 years now. Each year they select innovative products, services or business models that entrepreneurs develop on their own or with research institutions, and have the potential for growth."
    },
    {
        image: require('./images/articles/delo.jpg'),
        title: 'We are presented in Delo',
        date: '2014-08-09',
        content: "Delo newspaper has written an interesting article about our company Sunesis. They named the director of the company Eva Zupancic as <a target='_blank' href='http://www.delo.si/gospodarstvo/podjetja/ime-dneva-eva-zupancic-sunesis.html' >the name of the week</a>."
    },
    {
        title: 'Philipe Tjon A Hen - KumuluzEE - developing microservices using Java EE technologies',
        date: '2017-04-10',
        content: 'KumuluzEE is een lichtgewicht framework om microservices mee te bouwen die gebruik maken van standaard JavaEE technologie. Kunnen we gebruikmakend van standa... <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=oLHy2VI00G0">Link to video</a>'
    },
    {
        title: 'Dolphin Platform & KumuluzEE: JavaEE Microservices with dynamic and rich frontends',
        date: '2015-11-01',
        content: 'Have you heard of KumuluzEE that is one of the 2015 Java Duke’s Choice Award winners? Well, if you haven’t heard about this cool project you should have a look. KumuluzEE is a JavaEE...<a target="_blank" rel="noopener noreferrer" href="http://www.guigarage.com/2015/11/dolphin-platform-kumuluzee-javaee-microservices-with-dynamic-and-rich-frontends/">Link</a>'
    },
    {
        title: 'Implementasi microservice dengan Java EE pada KumuluzEE',
        date: '2016-03-15',
        content: 'Melanjutkan blog sebelumnya, kali ini kita akan membuat REST API menggunakan standar Java EE pada framework KumuluzEE. Tambahkan modul...<a target="_blank" rel="noopener noreferrer" href="http://blog.nostratech.com/2016/03/implementasi-microservice-dengan-java.html">Link</a>'
    },
    {
        title: 'Java EE and Microservices in 2016?',
        date: '2016-02-14',
        content: 'At the end of 2015 Steve Millidge from C2B2 and a co-founder of Payara predicted that 2016 would be the year of Java EE microservices. Many efforts would tend to agree, including WildFly, TomEE and the KumuluzEE framework... <a target="_blank" rel="noopener noreferrer" href="https://www.infoq.com/news/2016/02/javaee-microservices">Link</a>'
    },
    {
        title: 'Conheça o KumuluzEE – seu novo framework para Microservices',
        date: '2016-07-04',
        content: 'Parece que a nova buzzword é realmente Microservices. A definição do Adam Bien parece perfeita: é um serviço que tem máxima coesão e mínimo acoplamento. <a target="_blank" rel="noopener noreferrer" href="http://blog.caelum.com.br/conheca-o-kumuluzee-seu-novo-framework-para-microservices/">Link</a>'
    },
    {
        title: 'Docker, Kubernetes, and Microservices Replay from Devoxx 2015',
        date: '2015-11-12',
        content: 'Java gives us Write Once Run Anywhere (WORA) because of the common abstraction provided by Java Virtual Machine. The binary byte code produced by Java is understood by the JVM running on multiple operating systems... <a target="_blank" rel="noopener noreferrer" href="http://blog.arungupta.me/docker-kubernetes-microservices-devoxx-2015/">Link</a>'
    },
    {
        title: 'Implementing Microservices Using KumuluzEE - DZone Integration',
        date: '2015-11-17',
        content: 'Here\'s a great guide to getting started with microservices on KumuluzEE. <a target="_blank" rel="noopener noreferrer" href="https://dzone.com/articles/implementing-microservices-using-kumuluzee">Link</a>'
    },
    {
        title: 'Scalable Microservices Using Modern Java-Based Frameworks - DZone Integration',
        date: '2016-08-15',
        content: 'Take a look at KumuluzEE, a Java-based framework specifically designed for developers tooling around with microservices. <a target="_blank" rel="noopener noreferrer" href="https://dzone.com/articles/scalable-microservices-using-kumuluz-ee-framework">Link</a>'
    },
    {
        title: 'Java EE MicroProfile With KumuluzEE - DZone Java',
        date: '2017-08-01',
        content: 'Despite MicroProfile\'s hype, KumuluzEE already offers service discovery, registration, and configuration support for microservices in a Java EE environment. <a target="_blank" rel="noopener noreferrer" href="https://dzone.com/articles/java-ee-microprofile-with-kumuluzee">Link</a>'
    },
    {
        title: 'Eclipse MicroProfile: 5 Things You Need to Know',
        date: '2017-09-18',
        content: 'KumuluzEE has the fastest startup and smallest footprint microservices. <a target="_blank" rel="noopener noreferrer" href="https://readlearncode.com/java-ee/eclipse-microprofile-5-things-you-need-to-know/">Link</a>'
    },
    {
        title: 'Building a resilient Microservice? All you need is Eclipse MicroProfile Fault Tolerance!',
        date: '2017-09-02',
        content: 'Building a resilient microservice? No need to ask why... <a target="_blank" rel="noopener noreferrer" href="http://www.eclipse.org/community/eclipse_newsletter/2017/september/article4.php">Link</a>'
    },
    {
        title: 'Eclipse Microservices and Service Discovery',
        date: '2017-09-01',
        content: 'Microservices are becoming increasingly important for enterprise applications in general and for the Java EE platform, which is a widely used... <a target="_blank" rel="noopener noreferrer" href="http://www.eclipse.org/community/eclipse_newsletter/2017/september/article5.php">Link</a>'
    },
    {
        title: 'Eclipse Microservices and Service Discovery',
        date: '2017-11-06',
        content: 'Discover service discovery, an essential part of microservice architecture becomes necessary when we start using containers and container orchestration mechanisms. <a target="_blank" rel="noopener noreferrer" href="https://jaxenter.com/eclipse-microservices-service-discovery-138577.html">Link</a>'
    },
    {
        title: 'Java EE 8 Microservices',
        date: '2017-11-27',
        content: 'Build the foundation of Cloud Native applications with microservices using Java EE 8. <a target="_blank" rel="noopener noreferrer" href="https://www.packtpub.com/application-development/java-ee-8-microservices-video">Link</a>'
    },
    {
        title: 'Java EE Microservices on Kubernetes With KumuluzEE - DZone Microservices',
        date: '2018-01-10',
        content: 'Deploy and autoscale KumuluzEE microservices on Kubernetes with service discovery, configuration and health checks. <a target="_blank" rel="noopener noreferrer" href="https://dzone.com/articles/java-ee-microservices-on-kubernetes-with-kumuluzee">Link</a>'
    },
    {
        title: 'KumuluzEE on Oracle Application Container Cloud',
        date: '2018-03-22',
        content: 'In this blog post, I will describe how to deploy the CloudEE Duke application packaged in a Kumuluz EE über-jar to Oracle Application Container Cloud. <a target="_blank" rel="noopener noreferrer" href="http://www.agilejava.eu/2018/03/22/kumuluzee-on-oracle-application-container-cloud/">Link</a>'
    },
    {
        title: 'Java EE Microservices on Kubernetes With KumuluzEE',
        date: '2020-02-22',
        content: 'We will demonstrate how KumuluzEE microservice framework is able to make use of the Kubernetes utilities for optimal execution of the microservices. <a target="_blank" rel="noopener noreferrer" href="https://javantura.com/sessions_v5/#kumuluzee">Link</a>'
    },
    {
        title: 'Evaluierung und Skizzierung einer Servicearchitektur für einen klassischen Monolithen',
        date: '2020-01-01',
        content: 'Eine gewachsene Anwendung soll in einer SOA überführt werden im Hinblick auf Microservices. In dem Rahmen wurden Technologien wie JEE, SpringBoot oder auch KumuluzEE oder JHipster evaluiert. Swagger wurde als API Dokumentation ausgewählt, wobei API Driven Design direkt nicht gewünscht ist. Daraus wurden Handlungesempfehlungen abgeleitet, sowie die Softwareentwickler geschult. <a target="_blank" rel="noopener noreferrer" href="https://www.informatikwerk.de/portfolio/monolith_service_migration_architektur/">Link</a>'
    },
    {
        title: 'KumuluzEE for Standalone Java EE Microservices',
        date: '2018-09-09',
        content: 'In this post, I would like to start with KumuluzEE which advertises the easy migration of Java EE applications to cloud-native microservices on it’s website. The advantage, like with Thorntail, to me is that I can code against the regular Java EE APIs and thus do not have to learn a new framework. <a target="_blank" rel="noopener noreferrer" href="http://dplatz.de/blog/2018/kumuluzee.html">Link</a>'
    },
    {
        title: 'Jakarta EE 8 Recipes',
        date: '2019-07-01',
        content: 'Jakarta EE 8 Recipes by Sherwin John Calleja Tragura. Publisher(s): Packt Publishing. ISBN 9781789958607'
    },
    {
        title: 'GraphQL with KumuluzEE',
        date: '2019-03-08',
        content: 'In the context of my contribution to the recently started MicroProfile GraphQL initiative, I’ve decided to give KumuluzEE GraphQL a try in order to  get some interesting inputs. <a target="_blank" rel="noopener noreferrer" href="https://jefrajames.fr/2019/03/08/graphql-with-kumuluzee/">Link</a>'
    },
    {
        title: 'Apache Kafka: streamline communication across your microservices',
        date: '2019-10-09',
        content: 'Apache Kafka is a community-distributed streaming platform that has three key capabilities: publish and subscribe to streams of records, store streams of records in a fault-tolerant durable way, and process streams as they occur. <a target="_blank" rel="noopener noreferrer" href="https://platform.sh/blog/2019/apache-kafka-streamline-communication-across-your-microservices/">Link</a>'
    },
    {
        title: 'An Analysis of Frameworks for Microservices',
        date: '2018-10-01',
        content: 'In this paper, we present a qualitative study that compares both KumuluzEE and Spring Cloud & NetFlix OSS frameworks through functional and non-functional requirements. <a target="_blank" rel="noopener noreferrer" href="https://ieeexplore.ieee.org/document/8786337">Link</a>'
    },
    {
        title: 'MicroProfile Runtimes Overview (Adam Bien)',
        date: '2019-10-24',
        content: '<a target="_blank" rel="noopener noreferrer" href="https://www.adam-bien.com/roller/abien/entry/microprofile_runtimes_overview">Link</a>'
    },
    {
        title: 'Vom Enterprise-Java-Monolithen zu Microservicesin der Container-Welt',
        date: '2019-09-26',
        content: 'OWLTech & Innovation Day <a target="_blank" rel="noopener noreferrer" href="https://teuto.net/wp-content/uploads/2019/10/Vom-Enterprise-Java-Monolithen-zu-Microservices-in-der-Container-WeltDW.pdf">Link</a>'
    },
    {
        title: 'Building an API Backend with MicroProfile',
        date: '2019-12-19',
        content: 'This book is for Java developers that want to quickly learn how to create a REST API using MicroProfile. <a target="_blank" rel="noopener noreferrer" href="https://cicekhayri.github.io/ebook-Building-an-API-Backend-with-MicroProfile/building-an-api-backend-with-microprofile.html">Link</a>'
    },
    {
        title: 'MicroProfile Runtimes Overview (Eclipse Foundation)',
        date: '2019-10-24',
        content: '<a target="_blank" rel="noopener noreferrer" href="https://www.eclipse.org/community/eclipse_newsletter/2019/november/2.php">Link</a>'
    }
]
