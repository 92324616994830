exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-awards-index-js": () => import("./../../../src/pages/about/awards/index.js" /* webpackChunkName: "component---src-pages-about-awards-index-js" */),
  "component---src-pages-about-references-index-js": () => import("./../../../src/pages/about/references/index.js" /* webpackChunkName: "component---src-pages-about-references-index-js" */),
  "component---src-pages-about-sunesis-index-js": () => import("./../../../src/pages/about/sunesis/index.js" /* webpackChunkName: "component---src-pages-about-sunesis-index-js" */),
  "component---src-pages-accountdelete-index-js": () => import("./../../../src/pages/accountdelete/index.js" /* webpackChunkName: "component---src-pages-accountdelete-index-js" */),
  "component---src-pages-customers-index-js": () => import("./../../../src/pages/customers/index.js" /* webpackChunkName: "component---src-pages-customers-index-js" */),
  "component---src-pages-edison-index-js": () => import("./../../../src/pages/edison/index.js" /* webpackChunkName: "component---src-pages-edison-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-ai-knowledge-mentor-index-js": () => import("./../../../src/pages/products/ai-knowledge-mentor/index.js" /* webpackChunkName: "component---src-pages-products-ai-knowledge-mentor-index-js" */),
  "component---src-pages-products-ai-smart-assistant-index-js": () => import("./../../../src/pages/products/ai-smart-assistant/index.js" /* webpackChunkName: "component---src-pages-products-ai-smart-assistant-index-js" */),
  "component---src-pages-products-business-api-index-js": () => import("./../../../src/pages/products/business-api/index.js" /* webpackChunkName: "component---src-pages-products-business-api-index-js" */),
  "component---src-pages-products-digital-platform-index-js": () => import("./../../../src/pages/products/digital-platform/index.js" /* webpackChunkName: "component---src-pages-products-digital-platform-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-optimizacija-procesov-index-js": () => import("./../../../src/pages/projects/optimizacija-procesov/index.js" /* webpackChunkName: "component---src-pages-projects-optimizacija-procesov-index-js" */),
  "component---src-pages-services-ai-index-js": () => import("./../../../src/pages/services/ai/index.js" /* webpackChunkName: "component---src-pages-services-ai-index-js" */),
  "component---src-pages-services-backbase-index-js": () => import("./../../../src/pages/services/backbase/index.js" /* webpackChunkName: "component---src-pages-services-backbase-index-js" */),
  "component---src-pages-services-modernization-index-js": () => import("./../../../src/pages/services/modernization/index.js" /* webpackChunkName: "component---src-pages-services-modernization-index-js" */),
  "component---src-pages-services-solutions-index-js": () => import("./../../../src/pages/services/solutions/index.js" /* webpackChunkName: "component---src-pages-services-solutions-index-js" */),
  "component---src-pages-technologies-index-js": () => import("./../../../src/pages/technologies/index.js" /* webpackChunkName: "component---src-pages-technologies-index-js" */),
  "component---src-pages-thanks-index-js": () => import("./../../../src/pages/thanks/index.js" /* webpackChunkName: "component---src-pages-thanks-index-js" */)
}

